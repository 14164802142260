import React, { useState, useEffect } from 'react';
import { useAddress, useDisconnect, useSDK, ConnectWallet } from "@thirdweb-dev/react";
import './VerifyNFT.css';

function VerifyNFT() {
  const address = useAddress();
  const disconnect = useDisconnect();
  const sdk = useSDK();
  
  const [token, setToken] = useState('');
  const [status, setStatus] = useState('');
  const [showRefresh, setShowRefresh] = useState(false);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const tokenParam = urlParams.get('token');
    if (tokenParam) {
      setToken(tokenParam);
    } else {
      setStatus('Invalid verification link. Please try again.');
    }
  }, []);

  const verifyNFT = async () => {
    if (!address || !sdk) {
      setStatus('Please connect your wallet first.');
      return;
    }

    try {
      setStatus('Signing message...');
      const message = `Verify SpaceM Node ownership on Avalanche. Token: ${token}`;
      const signature = await sdk.wallet.sign(message);

      setStatus('Verifying...');
      const response = await fetch('/submit-verification', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ token, address, signature }),
      });

      const result = await response.json();
      if (result.success) {
        setStatus(result.message);
        setShowRefresh(false);
      } else {
        setStatus(result.error);
        setShowRefresh(true);
      }
    } catch (error) {
      console.error('Error in verifyNFT:', error);
      setStatus('Failed to verify: ' + error.message);
    }
  };

  const refreshStatus = async () => {
    try {
      const response = await fetch(`/check-verification?token=${token}`);
      const result = await response.json();
      if (result.success) {
        setStatus(result.message);
        setShowRefresh(false);
      } else {
        setStatus(result.error);
      }
    } catch (error) {
      console.error('Error in refreshStatus:', error);
      setStatus('Failed to check verification status: ' + error.message);
    }
  };

  return (
    <div className="container">
      <h1>Verify SpaceM Node NFT Ownership</h1>
      {!address ? (
        <ConnectWallet 
          theme="dark"
          btnTitle="Connect Wallet"
          className="connect-wallet-button"
        />
      ) : (
        <>
          <p>Connected: {address}</p>
          <button onClick={disconnect}>Disconnect</button>
          <button onClick={verifyNFT}>Verify NFT Ownership</button>
        </>
      )}
      {showRefresh && <button onClick={refreshStatus}>Refresh Status</button>}
      <div className="status">{status}</div>
    </div>
  );
}

export default VerifyNFT;