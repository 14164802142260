import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { ThirdwebProvider } from "@thirdweb-dev/react";
import { Avalanche } from "@thirdweb-dev/chains";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ThirdwebProvider activeChain={Avalanche} clientId="bcff4b4c9928633011da69820250e0c9">
      <App />
    </ThirdwebProvider>
  </React.StrictMode>
);